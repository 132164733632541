<template>
  <main class="page-my-profile page-show-my-profile">
    <PageTitleContainer>
      <PageTitle removeMargin>Il mio profilo</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-primary" :to="{ name: 'profile.edit' }">Modifica</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <div class="table-responsive" v-if="profile">
        <table class="table table-condensed">
          <tbody>
          <tr>
            <th>Nome</th>
            <td>{{ profile.name }} {{ profile.surname }}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{ profile.email }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>

import $api from '../../../libs/Api/services/api';
import rolesMixin from '../../../mixins/roles';

export default {
  name: 'my-profile-show',
  mixins: [rolesMixin],
  components: {
    PageTitle: () => import('../../components/Layout/PageTitle'),
    PageTitleContainer: () => import('../../components/Layout/PageTitleContainer'),
  },
  data () {
    return {
      profile: null,
    };
  },
  async beforeRouteEnter (to, from, next) {
    try {
      const res = await Promise.all([
        $api.getMyProfile(),
      ]);
      return next(vm => {
        vm.profile = res[0]?.data || {};
      });
    } catch (error) {
      return next(from, vm => vm.$log.error(error));
    }
  },
};

</script>
